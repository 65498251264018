/*!
Copyright (C) 2020 Evolved Binary

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU Affero General Public License as
published by the Free Software Foundation, either version 3 of the
License, or (at your option) any later version.

This program is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU Affero General Public License for more details.

You should have received a copy of the GNU Affero General Public License
along with this program.  If not, see <https://www.gnu.org/licenses/>.
*/
html {
  height: 100%;
  max-height: 100%;
}

body {
  height: 100%;
  max-height: 100%;
  margin: 0;
  font-family: Gill Sans, Gill Sans MT, Calibri, Trebuchet MS, sans-serif;
  font-size: 14pt;
  display: flex;
}

.pt-container {
  width: 100%;
}

@media screen and (width >= 400px) {
  .pt-container {
    width: 60vw;
    margin: 4rem auto;
  }
}

.pt-container > h2 {
  margin-top: 0;
}

.main {
  background-color: #f5f5f5;
  flex: 1;
  display: flex;
}

.main.error {
  background-color: #fff;
}

.main.error pre {
  background-color: #f2f0f0;
  border: 1px solid gray;
  padding: 1rem;
}

.main .editor-container {
  flex-direction: column;
  flex: 1;
  display: flex;
}

.main .editor-container .header {
  color: #fff;
  background-color: #f16999;
  height: 50px;
  padding: 10px 24px;
}

.main .editor-container .header h1 {
  margin: 0;
  display: inline;
}

.main .editor-container .header img.eb-logo {
  float: right;
  width: 6em;
}

.main .editor-container #editor {
  background-color: #f3f3f3;
  flex: 1;
  display: flex;
  overflow: hidden;
}

.main .editor-container #editor .ProseMirror-menubar-wrapper {
  flex-direction: column;
  flex: 1;
  display: flex;
}

.main .editor-container #editor .ProseMirror-menubar-wrapper .ProseMirror-menubar {
  z-index: 1;
  background-color: #f3f2f1;
  flex-direction: row;
  align-items: center;
  display: flex;
  box-shadow: 0 1.6px 3.6px #0002, 0 .3px .9px #0000001c;
  min-height: auto !important;
}

.main .editor-container #editor .ProseMirror-menubar-wrapper .ProseMirror-menubar .ProseMirror-menuitem:first-child > * {
  margin-inline-start: 1rem;
}

.main .editor-container #editor .ProseMirror-menubar-wrapper .ProseMirror-menubar .ProseMirror-menuitem:last-child > * {
  margin-inline-end: 1rem;
}

.main .editor-container #editor .ProseMirror-menubar-wrapper .ProseMirror-menubar .ProseMirror-menuitem .label {
  align-items: center;
  gap: .4rem;
  display: flex;
}

.main .editor-container #editor .ProseMirror-menubar-wrapper .ProseMirror-menubar .ProseMirror-menuitem .ic-download {
  align-items: center;
  gap: .4rem;
  margin-left: 1rem;
  display: flex;
}

.main .editor-container #editor .ProseMirror-menubar-wrapper .ProseMirror-menubar .ProseMirror-menuitem .ic-download a {
  color: inherit;
  text-decoration: none;
}

.main .editor-container #editor .ProseMirror-menubar-wrapper .ProseMirror-menubar .ProseMirror-menuitem .ic-github.publish {
  align-items: center;
  gap: .4rem;
  margin-left: 1rem;
  display: flex;
}

.main .editor-container #editor .ProseMirror-menubar-wrapper .ProseMirror-menubar .ProseMirror-menuitem .ic-github.publish a {
  color: inherit;
  text-decoration: none;
}

.main .editor-container #editor .ProseMirror-menubar-wrapper .ProseMirror-menubar .ProseMirror-menuitem > * {
  cursor: pointer;
  white-space: nowrap;
  color: #252423;
  box-sizing: border-box;
  text-align: center;
  vertical-align: top;
  -webkit-user-select: none;
  user-select: none;
  background-color: #0000;
  border-radius: 0;
  min-width: 36px;
  height: 36px;
  font-size: 14px;
  font-weight: normal;
  line-height: 36px;
  text-decoration: none;
}

.main .editor-container #editor .ProseMirror-menubar-wrapper .ProseMirror-menubar .ProseMirror-menuitem > .separator {
  min-width: auto;
}

.main .editor-container #editor .ProseMirror-menubar-wrapper .ProseMirror-menubar .ProseMirror-menuitem > [class^="ic-"], .main .editor-container #editor .ProseMirror-menubar-wrapper .ProseMirror-menubar .ProseMirror-menuitem > [class*=" ic-"] {
  font-family: inherit !important;
}

.main .editor-container #editor .ProseMirror-menubar-wrapper .ProseMirror-menubar .ProseMirror-menuitem > [class^="ic-"]:before, .main .editor-container #editor .ProseMirror-menubar-wrapper .ProseMirror-menubar .ProseMirror-menuitem > [class*=" ic-"]:before {
  font-family: petal !important;
}

.main .editor-container #editor .ProseMirror-menubar-wrapper .ProseMirror-menubar .ProseMirror-menuitem > [class^="ic-"].ic-bug:before, .main .editor-container #editor .ProseMirror-menubar-wrapper .ProseMirror-menubar .ProseMirror-menuitem > [class*=" ic-"].ic-bug:before {
  margin-inline-end: .5em;
}

.main .editor-container #editor .ProseMirror-menubar-wrapper .ProseMirror-menubar .ProseMirror-menuitem > :hover {
  color: #252423;
  background-color: #edebe9;
  outline: 2px dashed #0000;
}

.main .editor-container #editor .ProseMirror-menubar-wrapper .ProseMirror-menubar .ProseMirror-menuitem > :active, .main .editor-container #editor .ProseMirror-menubar-wrapper .ProseMirror-menubar .ProseMirror-menuitem > .ProseMirror-menu-active:active {
  color: #252423;
  background-color: #d2d0ce;
}

.main .editor-container #editor .ProseMirror-menubar-wrapper .ProseMirror-menubar .ProseMirror-menuitem > .ProseMirror-menu-active {
  background-color: #e1dfdd;
  outline: 2px solid #0000;
}

.main .editor-container #editor .ProseMirror-menubar-wrapper .ProseMirror-menubar .ProseMirror-menuitem > .ProseMirror-menu-disabled {
  cursor: default;
  color: #a19f9d;
  pointer-events: none;
  border: 1px solid #0000;
}

.main .editor-container #editor .ProseMirror-menubar-wrapper .ProseMirror-menubar .ProseMirror-menuitem .ProseMirror-menuitem-file {
  position: relative;
  overflow: hidden;
}

.main .editor-container #editor .ProseMirror-menubar-wrapper .ProseMirror-menubar .ProseMirror-menuitem .ProseMirror-menuitem-file.ProseMirror-menu-disabled input {
  cursor: default;
}

.main .editor-container #editor .ProseMirror-menubar-wrapper .ProseMirror-menubar .ProseMirror-menuitem .ProseMirror-menuitem-file label {
  cursor: pointer;
}

.main .editor-container #editor .ProseMirror-menubar-wrapper .ProseMirror-menubar .ProseMirror-menuitem .ProseMirror-menuitem-file input {
  cursor: pointer;
  opacity: 0;
  height: 36px;
  position: absolute;
  inset: -100px 0 0;
}

.main .editor-container #editor .ProseMirror-menubar-wrapper .ProseMirror-menubar .ProseMirror-menuitem .ProseMirror-menuitem-file input[type="button"] {
  appearance: none;
  padding: 0;
  border: 0;
  padding-block: 0;
  padding-inline: 0;
}

.main .editor-container #editor .ProseMirror-menubar-wrapper .ProseMirror-menubar .ProseMirror-menuitem:has(.separator) {
  flex: 1;
}

.main .editor-container #editor .ProseMirror-menubar-wrapper .ProseMirror-menubar .ProseMirror-menuseparator {
  background-color: #c8c8c8;
  width: 1px;
  height: 28px;
  margin: 0 12px;
}

.main .editor-container #editor .ProseMirror {
  outline: none;
  padding: 32px 64px;
  overflow: auto;
}

.main .editor-container #editor .ProseMirror > * {
  background-color: #fff;
  max-width: 960px;
  margin-left: auto;
  margin-right: auto;
  padding: 1em;
}

.debug .main .editor-container #editor .ProseMirror [data-j-type="audio"] {
  border: 2px solid #0000ff0d;
  margin-left: 10px;
  position: relative;
}

.debug .main .editor-container #editor .ProseMirror [data-j-type="audio"]:after {
  color: #fff;
  content: "audio";
  transform-origin: 0 0;
  opacity: .1;
  background-color: #0000ffb3;
  padding: 0 4px;
  font-size: 12px;
  font-weight: 400;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(90deg);
}

.debug .main .editor-container #editor .ProseMirror [data-j-type="audio"]:hover {
  background-color: #0000000d;
  border: 2px solid #0000ff4d;
}

.debug .main .editor-container #editor .ProseMirror [data-j-type="audio"]:hover:after {
  opacity: 1;
}

.debug .main .editor-container #editor .ProseMirror [data-j-type="body"] {
  border: 2px solid #0000ff0d;
  margin-left: 10px;
  position: relative;
}

.debug .main .editor-container #editor .ProseMirror [data-j-type="body"]:after {
  color: #fff;
  content: "body";
  transform-origin: 0 0;
  opacity: .1;
  background-color: #0000ffb3;
  padding: 0 4px;
  font-size: 12px;
  font-weight: 400;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(90deg);
}

.debug .main .editor-container #editor .ProseMirror [data-j-type="body"]:hover {
  background-color: #0000000d;
  border: 2px solid #0000ff4d;
}

.debug .main .editor-container #editor .ProseMirror [data-j-type="body"]:hover:after {
  opacity: 1;
}

.debug .main .editor-container #editor .ProseMirror [data-j-type="data"] {
  border: 2px solid #0000ff0d;
  margin-left: 10px;
  position: relative;
}

.debug .main .editor-container #editor .ProseMirror [data-j-type="data"]:after {
  color: #fff;
  content: "data";
  transform-origin: 0 0;
  opacity: .1;
  background-color: #0000ffb3;
  padding: 0 4px;
  font-size: 12px;
  font-weight: 400;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(90deg);
}

.debug .main .editor-container #editor .ProseMirror [data-j-type="data"]:hover {
  background-color: #0000000d;
  border: 2px solid #0000ff4d;
}

.debug .main .editor-container #editor .ProseMirror [data-j-type="data"]:hover:after {
  opacity: 1;
}

.debug .main .editor-container #editor .ProseMirror [data-j-type="dd"] {
  border: 2px solid #0000ff0d;
  margin-left: 10px;
  position: relative;
}

.debug .main .editor-container #editor .ProseMirror [data-j-type="dd"]:after {
  color: #fff;
  content: "dd";
  transform-origin: 0 0;
  opacity: .1;
  background-color: #0000ffb3;
  padding: 0 4px;
  font-size: 12px;
  font-weight: 400;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(90deg);
}

.debug .main .editor-container #editor .ProseMirror [data-j-type="dd"]:hover {
  background-color: #0000000d;
  border: 2px solid #0000ff4d;
}

.debug .main .editor-container #editor .ProseMirror [data-j-type="dd"]:hover:after {
  opacity: 1;
}

.debug .main .editor-container #editor .ProseMirror [data-j-type="desc"] {
  border: 2px solid #0000ff0d;
  margin-left: 10px;
  position: relative;
}

.debug .main .editor-container #editor .ProseMirror [data-j-type="desc"]:after {
  color: #fff;
  content: "desc";
  transform-origin: 0 0;
  opacity: .1;
  background-color: #0000ffb3;
  padding: 0 4px;
  font-size: 12px;
  font-weight: 400;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(90deg);
}

.debug .main .editor-container #editor .ProseMirror [data-j-type="desc"]:hover {
  background-color: #0000000d;
  border: 2px solid #0000ff4d;
}

.debug .main .editor-container #editor .ProseMirror [data-j-type="desc"]:hover:after {
  opacity: 1;
}

.debug .main .editor-container #editor .ProseMirror [data-j-type="dl"] {
  border: 2px solid #0000ff0d;
  margin-left: 10px;
  position: relative;
}

.debug .main .editor-container #editor .ProseMirror [data-j-type="dl"]:after {
  color: #fff;
  content: "dl";
  transform-origin: 0 0;
  opacity: .1;
  background-color: #0000ffb3;
  padding: 0 4px;
  font-size: 12px;
  font-weight: 400;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(90deg);
}

.debug .main .editor-container #editor .ProseMirror [data-j-type="dl"]:hover {
  background-color: #0000000d;
  border: 2px solid #0000ff4d;
}

.debug .main .editor-container #editor .ProseMirror [data-j-type="dl"]:hover:after {
  opacity: 1;
}

.debug .main .editor-container #editor .ProseMirror [data-j-type="dlentry"] {
  border: 2px solid #0000ff0d;
  margin-left: 10px;
  position: relative;
}

.debug .main .editor-container #editor .ProseMirror [data-j-type="dlentry"]:after {
  color: #fff;
  content: "dlentry";
  transform-origin: 0 0;
  opacity: .1;
  background-color: #0000ffb3;
  padding: 0 4px;
  font-size: 12px;
  font-weight: 400;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(90deg);
}

.debug .main .editor-container #editor .ProseMirror [data-j-type="dlentry"]:hover {
  background-color: #0000000d;
  border: 2px solid #0000ff4d;
}

.debug .main .editor-container #editor .ProseMirror [data-j-type="dlentry"]:hover:after {
  opacity: 1;
}

.debug .main .editor-container #editor .ProseMirror [data-j-type="dt"] {
  border: 2px solid #0000ff0d;
  margin-left: 10px;
  position: relative;
}

.debug .main .editor-container #editor .ProseMirror [data-j-type="dt"]:after {
  color: #fff;
  content: "dt";
  transform-origin: 0 0;
  opacity: .1;
  background-color: #0000ffb3;
  padding: 0 4px;
  font-size: 12px;
  font-weight: 400;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(90deg);
}

.debug .main .editor-container #editor .ProseMirror [data-j-type="dt"]:hover {
  background-color: #0000000d;
  border: 2px solid #0000ff4d;
}

.debug .main .editor-container #editor .ProseMirror [data-j-type="dt"]:hover:after {
  opacity: 1;
}

.debug .main .editor-container #editor .ProseMirror [data-j-type="document"] {
  border: 2px solid #0000ff0d;
  margin-left: 10px;
  position: relative;
}

.debug .main .editor-container #editor .ProseMirror [data-j-type="document"]:after {
  color: #fff;
  content: "document";
  transform-origin: 0 0;
  opacity: .1;
  background-color: #0000ffb3;
  padding: 0 4px;
  font-size: 12px;
  font-weight: 400;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(90deg);
}

.debug .main .editor-container #editor .ProseMirror [data-j-type="document"]:hover {
  background-color: #0000000d;
  border: 2px solid #0000ff4d;
}

.debug .main .editor-container #editor .ProseMirror [data-j-type="document"]:hover:after {
  opacity: 1;
}

.debug .main .editor-container #editor .ProseMirror [data-j-type="fig"] {
  border: 2px solid #0000ff0d;
  margin-left: 10px;
  position: relative;
}

.debug .main .editor-container #editor .ProseMirror [data-j-type="fig"]:after {
  color: #fff;
  content: "fig";
  transform-origin: 0 0;
  opacity: .1;
  background-color: #0000ffb3;
  padding: 0 4px;
  font-size: 12px;
  font-weight: 400;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(90deg);
}

.debug .main .editor-container #editor .ProseMirror [data-j-type="fig"]:hover {
  background-color: #0000000d;
  border: 2px solid #0000ff4d;
}

.debug .main .editor-container #editor .ProseMirror [data-j-type="fig"]:hover:after {
  opacity: 1;
}

.debug .main .editor-container #editor .ProseMirror [data-j-type="fn"] {
  border: 2px solid #0000ff0d;
  margin-left: 10px;
  position: relative;
}

.debug .main .editor-container #editor .ProseMirror [data-j-type="fn"]:after {
  color: #fff;
  content: "fn";
  transform-origin: 0 0;
  opacity: .1;
  background-color: #0000ffb3;
  padding: 0 4px;
  font-size: 12px;
  font-weight: 400;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(90deg);
}

.debug .main .editor-container #editor .ProseMirror [data-j-type="fn"]:hover {
  background-color: #0000000d;
  border: 2px solid #0000ff4d;
}

.debug .main .editor-container #editor .ProseMirror [data-j-type="fn"]:hover:after {
  opacity: 1;
}

.debug .main .editor-container #editor .ProseMirror [data-j-type="image"] {
  border: 2px solid #0000ff0d;
  margin-left: 10px;
  position: relative;
}

.debug .main .editor-container #editor .ProseMirror [data-j-type="image"]:after {
  color: #fff;
  content: "image";
  transform-origin: 0 0;
  opacity: .1;
  background-color: #0000ffb3;
  padding: 0 4px;
  font-size: 12px;
  font-weight: 400;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(90deg);
}

.debug .main .editor-container #editor .ProseMirror [data-j-type="image"]:hover {
  background-color: #0000000d;
  border: 2px solid #0000ff4d;
}

.debug .main .editor-container #editor .ProseMirror [data-j-type="image"]:hover:after {
  opacity: 1;
}

.debug .main .editor-container #editor .ProseMirror [data-j-type="media-source"] {
  border: 2px solid #0000ff0d;
  margin-left: 10px;
  position: relative;
}

.debug .main .editor-container #editor .ProseMirror [data-j-type="media-source"]:after {
  color: #fff;
  content: "media-source";
  transform-origin: 0 0;
  opacity: .1;
  background-color: #0000ffb3;
  padding: 0 4px;
  font-size: 12px;
  font-weight: 400;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(90deg);
}

.debug .main .editor-container #editor .ProseMirror [data-j-type="media-source"]:hover {
  background-color: #0000000d;
  border: 2px solid #0000ff4d;
}

.debug .main .editor-container #editor .ProseMirror [data-j-type="media-source"]:hover:after {
  opacity: 1;
}

.debug .main .editor-container #editor .ProseMirror [data-j-type="media-track"] {
  border: 2px solid #0000ff0d;
  margin-left: 10px;
  position: relative;
}

.debug .main .editor-container #editor .ProseMirror [data-j-type="media-track"]:after {
  color: #fff;
  content: "media-track";
  transform-origin: 0 0;
  opacity: .1;
  background-color: #0000ffb3;
  padding: 0 4px;
  font-size: 12px;
  font-weight: 400;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(90deg);
}

.debug .main .editor-container #editor .ProseMirror [data-j-type="media-track"]:hover {
  background-color: #0000000d;
  border: 2px solid #0000ff4d;
}

.debug .main .editor-container #editor .ProseMirror [data-j-type="media-track"]:hover:after {
  opacity: 1;
}

.debug .main .editor-container #editor .ProseMirror [data-j-type="li"] {
  border: 2px solid #0000ff0d;
  margin-left: 10px;
  position: relative;
}

.debug .main .editor-container #editor .ProseMirror [data-j-type="li"]:after {
  color: #fff;
  content: "li";
  transform-origin: 0 0;
  opacity: .1;
  background-color: #0000ffb3;
  padding: 0 4px;
  font-size: 12px;
  font-weight: 400;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(90deg);
}

.debug .main .editor-container #editor .ProseMirror [data-j-type="li"]:hover {
  background-color: #0000000d;
  border: 2px solid #0000ff4d;
}

.debug .main .editor-container #editor .ProseMirror [data-j-type="li"]:hover:after {
  opacity: 1;
}

.debug .main .editor-container #editor .ProseMirror [data-j-type="note"] {
  border: 2px solid #0000ff0d;
  margin-left: 10px;
  position: relative;
}

.debug .main .editor-container #editor .ProseMirror [data-j-type="note"]:after {
  color: #fff;
  content: "note";
  transform-origin: 0 0;
  opacity: .1;
  background-color: #0000ffb3;
  padding: 0 4px;
  font-size: 12px;
  font-weight: 400;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(90deg);
}

.debug .main .editor-container #editor .ProseMirror [data-j-type="note"]:hover {
  background-color: #0000000d;
  border: 2px solid #0000ff4d;
}

.debug .main .editor-container #editor .ProseMirror [data-j-type="note"]:hover:after {
  opacity: 1;
}

.debug .main .editor-container #editor .ProseMirror [data-j-type="ol"] {
  border: 2px solid #0000ff0d;
  margin-left: 10px;
  position: relative;
}

.debug .main .editor-container #editor .ProseMirror [data-j-type="ol"]:after {
  color: #fff;
  content: "ol";
  transform-origin: 0 0;
  opacity: .1;
  background-color: #0000ffb3;
  padding: 0 4px;
  font-size: 12px;
  font-weight: 400;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(90deg);
}

.debug .main .editor-container #editor .ProseMirror [data-j-type="ol"]:hover {
  background-color: #0000000d;
  border: 2px solid #0000ff4d;
}

.debug .main .editor-container #editor .ProseMirror [data-j-type="ol"]:hover:after {
  opacity: 1;
}

.debug .main .editor-container #editor .ProseMirror [data-j-type="p"] {
  border: 2px solid #0000ff0d;
  margin-left: 10px;
  position: relative;
}

.debug .main .editor-container #editor .ProseMirror [data-j-type="p"]:after {
  color: #fff;
  content: "p";
  transform-origin: 0 0;
  opacity: .1;
  background-color: #0000ffb3;
  padding: 0 4px;
  font-size: 12px;
  font-weight: 400;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(90deg);
}

.debug .main .editor-container #editor .ProseMirror [data-j-type="p"]:hover {
  background-color: #0000000d;
  border: 2px solid #0000ff4d;
}

.debug .main .editor-container #editor .ProseMirror [data-j-type="p"]:hover:after {
  opacity: 1;
}

.debug .main .editor-container #editor .ProseMirror [data-j-type="ph"] {
  border: 2px solid #0000ff0d;
  margin-left: 10px;
  position: relative;
}

.debug .main .editor-container #editor .ProseMirror [data-j-type="ph"]:after {
  color: #fff;
  content: "ph";
  transform-origin: 0 0;
  opacity: .1;
  background-color: #0000ffb3;
  padding: 0 4px;
  font-size: 12px;
  font-weight: 400;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(90deg);
}

.debug .main .editor-container #editor .ProseMirror [data-j-type="ph"]:hover {
  background-color: #0000000d;
  border: 2px solid #0000ff4d;
}

.debug .main .editor-container #editor .ProseMirror [data-j-type="ph"]:hover:after {
  opacity: 1;
}

.debug .main .editor-container #editor .ProseMirror [data-j-type="pre"] {
  border: 2px solid #0000ff0d;
  margin-left: 10px;
  position: relative;
}

.debug .main .editor-container #editor .ProseMirror [data-j-type="pre"]:after {
  color: #fff;
  content: "pre";
  transform-origin: 0 0;
  opacity: .1;
  background-color: #0000ffb3;
  padding: 0 4px;
  font-size: 12px;
  font-weight: 400;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(90deg);
}

.debug .main .editor-container #editor .ProseMirror [data-j-type="pre"]:hover {
  background-color: #0000000d;
  border: 2px solid #0000ff4d;
}

.debug .main .editor-container #editor .ProseMirror [data-j-type="pre"]:hover:after {
  opacity: 1;
}

.debug .main .editor-container #editor .ProseMirror [data-j-type="prolog"] {
  border: 2px solid #0000ff0d;
  margin-left: 10px;
  position: relative;
}

.debug .main .editor-container #editor .ProseMirror [data-j-type="prolog"]:after {
  color: #fff;
  content: "prolog";
  transform-origin: 0 0;
  opacity: .1;
  background-color: #0000ffb3;
  padding: 0 4px;
  font-size: 12px;
  font-weight: 400;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(90deg);
}

.debug .main .editor-container #editor .ProseMirror [data-j-type="prolog"]:hover {
  background-color: #0000000d;
  border: 2px solid #0000ff4d;
}

.debug .main .editor-container #editor .ProseMirror [data-j-type="prolog"]:hover:after {
  opacity: 1;
}

.debug .main .editor-container #editor .ProseMirror [data-j-type="section"] {
  border: 2px solid #0000ff0d;
  margin-left: 10px;
  position: relative;
}

.debug .main .editor-container #editor .ProseMirror [data-j-type="section"]:after {
  color: #fff;
  content: "section";
  transform-origin: 0 0;
  opacity: .1;
  background-color: #0000ffb3;
  padding: 0 4px;
  font-size: 12px;
  font-weight: 400;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(90deg);
}

.debug .main .editor-container #editor .ProseMirror [data-j-type="section"]:hover {
  background-color: #0000000d;
  border: 2px solid #0000ff4d;
}

.debug .main .editor-container #editor .ProseMirror [data-j-type="section"]:hover:after {
  opacity: 1;
}

.debug .main .editor-container #editor .ProseMirror [data-j-type="simpletable"] {
  border: 2px solid #0000ff0d;
  margin-left: 10px;
  position: relative;
}

.debug .main .editor-container #editor .ProseMirror [data-j-type="simpletable"]:after {
  color: #fff;
  content: "simpletable";
  transform-origin: 0 0;
  opacity: .1;
  background-color: #0000ffb3;
  padding: 0 4px;
  font-size: 12px;
  font-weight: 400;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(90deg);
}

.debug .main .editor-container #editor .ProseMirror [data-j-type="simpletable"]:hover {
  background-color: #0000000d;
  border: 2px solid #0000ff4d;
}

.debug .main .editor-container #editor .ProseMirror [data-j-type="simpletable"]:hover:after {
  opacity: 1;
}

.debug .main .editor-container #editor .ProseMirror [data-j-type="shortdesc"] {
  border: 2px solid #0000ff0d;
  margin-left: 10px;
  position: relative;
}

.debug .main .editor-container #editor .ProseMirror [data-j-type="shortdesc"]:after {
  color: #fff;
  content: "shortdesc";
  transform-origin: 0 0;
  opacity: .1;
  background-color: #0000ffb3;
  padding: 0 4px;
  font-size: 12px;
  font-weight: 400;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(90deg);
}

.debug .main .editor-container #editor .ProseMirror [data-j-type="shortdesc"]:hover {
  background-color: #0000000d;
  border: 2px solid #0000ff4d;
}

.debug .main .editor-container #editor .ProseMirror [data-j-type="shortdesc"]:hover:after {
  opacity: 1;
}

.debug .main .editor-container #editor .ProseMirror [data-j-type="stentry"] {
  border: 2px solid #0000ff0d;
  margin-left: 10px;
  position: relative;
}

.debug .main .editor-container #editor .ProseMirror [data-j-type="stentry"]:after {
  color: #fff;
  content: "stentry";
  transform-origin: 0 0;
  opacity: .1;
  background-color: #0000ffb3;
  padding: 0 4px;
  font-size: 12px;
  font-weight: 400;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(90deg);
}

.debug .main .editor-container #editor .ProseMirror [data-j-type="stentry"]:hover {
  background-color: #0000000d;
  border: 2px solid #0000ff4d;
}

.debug .main .editor-container #editor .ProseMirror [data-j-type="stentry"]:hover:after {
  opacity: 1;
}

.debug .main .editor-container #editor .ProseMirror [data-j-type="sthead"] {
  border: 2px solid #0000ff0d;
  margin-left: 10px;
  position: relative;
}

.debug .main .editor-container #editor .ProseMirror [data-j-type="sthead"]:after {
  color: #fff;
  content: "sthead";
  transform-origin: 0 0;
  opacity: .1;
  background-color: #0000ffb3;
  padding: 0 4px;
  font-size: 12px;
  font-weight: 400;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(90deg);
}

.debug .main .editor-container #editor .ProseMirror [data-j-type="sthead"]:hover {
  background-color: #0000000d;
  border: 2px solid #0000ff4d;
}

.debug .main .editor-container #editor .ProseMirror [data-j-type="sthead"]:hover:after {
  opacity: 1;
}

.debug .main .editor-container #editor .ProseMirror [data-j-type="strow"] {
  border: 2px solid #0000ff0d;
  margin-left: 10px;
  position: relative;
}

.debug .main .editor-container #editor .ProseMirror [data-j-type="strow"]:after {
  color: #fff;
  content: "strow";
  transform-origin: 0 0;
  opacity: .1;
  background-color: #0000ffb3;
  padding: 0 4px;
  font-size: 12px;
  font-weight: 400;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(90deg);
}

.debug .main .editor-container #editor .ProseMirror [data-j-type="strow"]:hover {
  background-color: #0000000d;
  border: 2px solid #0000ff4d;
}

.debug .main .editor-container #editor .ProseMirror [data-j-type="strow"]:hover:after {
  opacity: 1;
}

.debug .main .editor-container #editor .ProseMirror [data-j-type="title"] {
  border: 2px solid #0000ff0d;
  margin-left: 10px;
  position: relative;
}

.debug .main .editor-container #editor .ProseMirror [data-j-type="title"]:after {
  color: #fff;
  content: "title";
  transform-origin: 0 0;
  opacity: .1;
  background-color: #0000ffb3;
  padding: 0 4px;
  font-size: 12px;
  font-weight: 400;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(90deg);
}

.debug .main .editor-container #editor .ProseMirror [data-j-type="title"]:hover {
  background-color: #0000000d;
  border: 2px solid #0000ff4d;
}

.debug .main .editor-container #editor .ProseMirror [data-j-type="title"]:hover:after {
  opacity: 1;
}

.debug .main .editor-container #editor .ProseMirror [data-j-type="topic"] {
  border: 2px solid #0000ff0d;
  margin-left: 10px;
  position: relative;
  margin-left: auto !important;
}

.debug .main .editor-container #editor .ProseMirror [data-j-type="topic"]:after {
  color: #fff;
  content: "topic";
  transform-origin: 0 0;
  opacity: .1;
  background-color: #0000ffb3;
  padding: 0 4px;
  font-size: 12px;
  font-weight: 400;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(90deg);
}

.debug .main .editor-container #editor .ProseMirror [data-j-type="topic"]:hover {
  background-color: #0000000d;
  border: 2px solid #0000ff4d;
}

.debug .main .editor-container #editor .ProseMirror [data-j-type="topic"]:hover:after {
  opacity: 1;
}

.debug .main .editor-container #editor .ProseMirror [data-j-type="ul"] {
  border: 2px solid #0000ff0d;
  margin-left: 10px;
  position: relative;
}

.debug .main .editor-container #editor .ProseMirror [data-j-type="ul"]:after {
  color: #fff;
  content: "ul";
  transform-origin: 0 0;
  opacity: .1;
  background-color: #0000ffb3;
  padding: 0 4px;
  font-size: 12px;
  font-weight: 400;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(90deg);
}

.debug .main .editor-container #editor .ProseMirror [data-j-type="ul"]:hover {
  background-color: #0000000d;
  border: 2px solid #0000ff4d;
}

.debug .main .editor-container #editor .ProseMirror [data-j-type="ul"]:hover:after {
  opacity: 1;
}

.debug .main .editor-container #editor .ProseMirror [data-j-type="video"] {
  border: 2px solid #0000ff0d;
  margin-left: 10px;
  position: relative;
}

.debug .main .editor-container #editor .ProseMirror [data-j-type="video"]:after {
  color: #fff;
  content: "video";
  transform-origin: 0 0;
  opacity: .1;
  background-color: #0000ffb3;
  padding: 0 4px;
  font-size: 12px;
  font-weight: 400;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(90deg);
}

.debug .main .editor-container #editor .ProseMirror [data-j-type="video"]:hover {
  background-color: #0000000d;
  border: 2px solid #0000ff4d;
}

.debug .main .editor-container #editor .ProseMirror [data-j-type="video"]:hover:after {
  opacity: 1;
}

.debug .main .editor-container #editor .ProseMirror [data-j-type="xref"] {
  border: 2px solid #0000ff0d;
  margin-left: 10px;
  position: relative;
}

.debug .main .editor-container #editor .ProseMirror [data-j-type="xref"]:after {
  color: #fff;
  content: "xref";
  transform-origin: 0 0;
  opacity: .1;
  background-color: #0000ffb3;
  padding: 0 4px;
  font-size: 12px;
  font-weight: 400;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(90deg);
}

.debug .main .editor-container #editor .ProseMirror [data-j-type="xref"]:hover {
  background-color: #0000000d;
  border: 2px solid #0000ff4d;
}

.debug .main .editor-container #editor .ProseMirror [data-j-type="xref"]:hover:after {
  opacity: 1;
}

.debug .main .editor-container #editor .ProseMirror [data-j-type="b"] {
  border: 2px solid #0000ff0d;
  margin-left: 10px;
  position: relative;
}

.debug .main .editor-container #editor .ProseMirror [data-j-type="b"]:after {
  color: #fff;
  content: "b";
  transform-origin: 0 0;
  opacity: .1;
  background-color: #0000ffb3;
  padding: 0 4px;
  font-size: 12px;
  font-weight: 400;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(90deg);
}

.debug .main .editor-container #editor .ProseMirror [data-j-type="b"]:hover {
  background-color: #0000000d;
  border: 2px solid #0000ff4d;
}

.debug .main .editor-container #editor .ProseMirror [data-j-type="b"]:hover:after {
  opacity: 1;
}

.debug .main .editor-container #editor .ProseMirror [data-j-type="i"] {
  border: 2px solid #0000ff0d;
  margin-left: 10px;
  position: relative;
}

.debug .main .editor-container #editor .ProseMirror [data-j-type="i"]:after {
  color: #fff;
  content: "i";
  transform-origin: 0 0;
  opacity: .1;
  background-color: #0000ffb3;
  padding: 0 4px;
  font-size: 12px;
  font-weight: 400;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(90deg);
}

.debug .main .editor-container #editor .ProseMirror [data-j-type="i"]:hover {
  background-color: #0000000d;
  border: 2px solid #0000ff4d;
}

.debug .main .editor-container #editor .ProseMirror [data-j-type="i"]:hover:after {
  opacity: 1;
}

.debug .main .editor-container #editor .ProseMirror [data-j-type="u"] {
  border: 2px solid #0000ff0d;
  margin-left: 10px;
  position: relative;
}

.debug .main .editor-container #editor .ProseMirror [data-j-type="u"]:after {
  color: #fff;
  content: "u";
  transform-origin: 0 0;
  opacity: .1;
  background-color: #0000ffb3;
  padding: 0 4px;
  font-size: 12px;
  font-weight: 400;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(90deg);
}

.debug .main .editor-container #editor .ProseMirror [data-j-type="u"]:hover {
  background-color: #0000000d;
  border: 2px solid #0000ff4d;
}

.debug .main .editor-container #editor .ProseMirror [data-j-type="u"]:hover:after {
  opacity: 1;
}

.debug .main .editor-container #editor .ProseMirror [data-j-type="sub"] {
  border: 2px solid #0000ff0d;
  margin-left: 10px;
  position: relative;
}

.debug .main .editor-container #editor .ProseMirror [data-j-type="sub"]:after {
  color: #fff;
  content: "sub";
  transform-origin: 0 0;
  opacity: .1;
  background-color: #0000ffb3;
  padding: 0 4px;
  font-size: 12px;
  font-weight: 400;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(90deg);
}

.debug .main .editor-container #editor .ProseMirror [data-j-type="sub"]:hover {
  background-color: #0000000d;
  border: 2px solid #0000ff4d;
}

.debug .main .editor-container #editor .ProseMirror [data-j-type="sub"]:hover:after {
  opacity: 1;
}

.debug .main .editor-container #editor .ProseMirror [data-j-type="topic"] {
  margin-left: auto !important;
}

.debug .main .editor-container #editor .ProseMirror [data-j-type="sup"] {
  border: 2px solid #0000ff0d;
  margin-left: 10px;
  position: relative;
}

.debug .main .editor-container #editor .ProseMirror [data-j-type="sup"]:after {
  color: #fff;
  content: "sup";
  transform-origin: 0 0;
  opacity: .1;
  background-color: #0000ffb3;
  padding: 0 4px;
  font-size: 12px;
  font-weight: 400;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(90deg);
}

.debug .main .editor-container #editor .ProseMirror [data-j-type="sup"]:hover {
  background-color: #0000000d;
  border: 2px solid #0000ff4d;
}

.debug .main .editor-container #editor .ProseMirror [data-j-type="sup"]:hover:after {
  opacity: 1;
}

.main .editor-container #editor .ProseMirror [data-j-type="simpletable"] {
  border: 1px solid #000;
}

.main .editor-container #editor .ProseMirror [data-j-type="simpletable"] thead {
  font-weight: bold;
}

.main .editor-container #editor .ProseMirror [data-j-type="simpletable"] td {
  border: 1px solid #000;
}

.main .editor-container #editor .ProseMirror [data-j-keyref]:before {
  content: "[" attr(data-j-keyref) "]";
  background-color: khaki;
}

.main .editor-container #editor .ProseMirror [data-j-type="video"], .main .editor-container #editor .ProseMirror [data-j-type="figure"] {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.main .editor-container #editor .ProseMirror [data-j-type="image"] {
  max-width: 100%;
  display: inline-block;
}

.main .editor-container #editor .ProseMirror [data-j-type="image"]:first-child:last-child {
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.main .editor-container #editor .ProseMirror [data-j-type="p"] {
  min-height: 1em;
}

.main .editor-container #editor .ProseMirror [data-j-type="li"] > [data-j-type="p"]:first-child {
  margin-block-start: 0;
}

.main .editor-container #editor .ProseMirror [data-j-type="li"] > [data-j-type="p"]:last-child {
  margin-block-end: 0;
}

[data-j-type] {
  border: 1px solid #0000;
}

#overlay, #prOverlay {
  z-index: 2;
  text-align: center;
  background: #00000080;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

#prDialog {
  background: #fff;
  border-radius: 8px;
  flex-direction: column;
  align-items: start;
  min-width: 26rem;
  padding: 2.5rem 2rem;
  display: flex;
}

#prDialog h1 {
  text-align: left;
  margin: 0 0 2rem;
}

#prDialog label, #prDialog input, #prDialog textarea {
  font-size: 1rem;
}

#prDialog label {
  font-weight: 600;
}

#prForm {
  min-width: 26rem;
}

.pt__form-field:not(:last-child) {
  margin-bottom: 2rem;
}

.pt__form-field:last-child {
  margin-top: 3.5rem;
}

.pt__form-field > input, .pt__form-field > textarea {
  resize: vertical;
  padding: .5rem;
  font-family: inherit;
}

.pt__flex--column {
  flex-direction: column;
  align-items: flex-start;
  gap: .7rem;
  display: flex;
}

.pt__flex--row {
  flex-direction: row;
  justify-content: space-between;
  display: flex;
}

.pt__button, .toast--dismiss {
  appearance: none;
  border: 1px solid #767676;
  border-radius: 4px;
  font-size: 1rem;
  font-weight: 400;
}

.pt__label {
  text-align: left;
}

.pt__label--info {
  font-style: italic;
  font-weight: 400;
}

input[type="submit"] {
  color: #fff;
  background-color: #a43e62;
  border: 1px solid #a43e62;
}

input[type="button"], input[type="submit"], button {
  cursor: pointer;
}

#dialog {
  background: #fff;
  border-radius: 8px;
  width: 300px;
  padding: 20px;
}

#dialog h1 {
  margin-top: 0;
  font-size: 20px;
}

#dialog .field-container {
  align-items: center;
  margin-bottom: 10px;
  display: flex;
}

#dialog .field-container label {
  text-align: left;
  margin-right: 10px;
}

#dialog .field-container input {
  flex: 1;
}

#dialog #btnConatiner {
  justify-content: space-between;
  margin-top: .5rem;
  padding: 0 1rem;
  display: flex;
}

.toastify {
  color: #fff;
  opacity: 0;
  cursor: pointer;
  z-index: 2147483647;
  border-radius: 2px;
  max-width: calc(50% - 20px);
  padding: 12px 20px;
  text-decoration: none;
  transition: all .4s cubic-bezier(.215, .61, .355, 1);
  display: inline-block;
  position: fixed;
  box-shadow: 0 3px 6px -1px #0000001f, 0 10px 36px -4px #4d60e84d;
}

.toastify a {
  color: inherit;
}

.toastify.on {
  opacity: 1;
}

.toast-close {
  color: #fff;
  cursor: pointer;
  opacity: .4;
  background: none;
  border: 0;
  padding: 0 5px;
  font-family: inherit;
  font-size: 1em;
}

.toastify-right {
  right: 15px;
}

.toastify-left {
  left: 15px;
}

.toastify-top {
  top: -150px;
}

.toastify-bottom {
  bottom: -150px;
}

.toastify-rounded {
  border-radius: 25px;
}

.toastify-avatar {
  border-radius: 2px;
  width: 1.5em;
  height: 1.5em;
  margin: -7px 5px;
}

.toastify-center {
  max-width: -moz-fit-content;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
}

.toast--success {
  color: #fff;
  background-color: #4caf50;
}

.toast--error {
  color: #fff;
  background-color: #a5395e;
}

.toast--warning {
  background-color: #ff9800;
}

.toast--info {
  background-color: #00bcd4;
}

.toast--welcome {
  color: #fff;
  background-color: #1c3f7c;
}

.toast__panel {
  flex-direction: column-reverse;
  align-items: flex-end;
  width: 22rem;
  padding: 1.5rem 2.5rem 2rem;
  display: flex;
  top: 106px !important;
}

.toast__panel.toastify-right {
  right: 0 !important;
}

.toast--dismiss {
  margin: .3rem 0 0;
  padding: .5rem;
  font-family: inherit;
}

.toast-close {
  flex: 0 2rem;
}

@media only screen and (width <= 360px) {
  .toastify-right, .toastify-left {
    max-width: fit-content;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
  }
}
/*# sourceMappingURL=index.120da46c.css.map */
